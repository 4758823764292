<template>
  <!-- <main class="container"> -->
  <!-- <SectionHeading :heading="$t('Hi') + `, ${user.first_name}`" /> -->
  <!-- <ProfileForm /> -->
  <!-- </main> -->
  <div>
    <div
      class="bg-dark-blue text-white p-2 w-100 text-center mt-1"
      role="alert"
      v-if="user && anyEntrepreneur && user.document_uploaded && !user.document_verified"
    >
      {{
        $t(
          "Uploaded documents of the entrepeneur still need to be verified by administrator."
        )
      }}
    </div>
    <div
      class="bg-dark-blue text-white p-2 w-100 text-center mt-1"
      role="alert"
      v-if="
        user &&
        (user.preferredHousingType == null || user.preferredHousingType.length == 0)
      "
    >
      {{
        $t(
          "Preferences are not set. Please specify your preferences below in order to respond to listings."
        )
      }}
      <a class="text-white text-underline" href="#preferences">
        {{ $t("Add preferences here") }}
      </a>
    </div>
    <div
      class="bg-dark-blue text-white p-2 w-100 text-center mt-1"
      role="alert"
      v-if="user && !tenantsVerified"
    >
      {{
        $t(
          "Not all tenants are fully verified. Please start the verification in order to respond to lisitings."
        )
      }}
    </div>
    <div
      class="bg-dark-blue text-white p-2 w-100 text-center mt-1"
      role="alert"
      v-if="!checkIncome"
    >
      {{ $t("Enhanced screening has to be performed by the admin") }}
    </div>
    <div
      class="bg-dark-blue text-white p-2 w-100 text-center mt-1"
      role="alert"
      v-for="(tenant, key) in notVerifiedEntrepreneurs"
      :key="key"
    >
      {{ $t(`Documents for ${tenant.name} still need to be uploaded`) }}
    </div>

    <div class="bg-white px-5 py-2 border-bottom" v-if="user.ref_listing">
      <div class="d-flex">
        <img class="w-75px" :src="user.ref_listing.feature_image.name" />

        <div class="ml-3">
          <div>{{ user.ref_listing.title }}</div>
          <div class="text-gray-inactive" v-if="tenantsVerified == false">
            <i class="fa-solid fa-lock mr-1"></i>
            Complete all steps to respond to this listing
          </div>
          <div class="text-blue" v-else>
            <router-link :to="`/listings/${user.ref_listing.slug}`">
              View listing
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <Spinner v-if="!user || getLoadings.get_user"></Spinner>
    <div class="container" v-else>
      <p class="text-danger fw-bold text-capitalize" v-if="getErrors.profile">
        {{ getErrors.profile }}
      </p>
      <p class="text-danger fw-bold text-capitalize" v-if="getErrors.qii">
        {{ getErrors.qii }}
      </p>

      <h1 class="mb-4" v-if="user">{{ $t("Hi") + `, ${user.first_name}` }}</h1>

      <div class="row">
        <div class="col-md-6">
          <Card
            class="profile-card mb-3"
            v-for="index in user.totalPersons - user.tenants.length < 0
              ? 0
              : user.totalPersons - user.tenants.length"
            :key="`new_${index}`"
          >
            <div class="row">
              <div class="col-md-12 pb-0">
                <h5 class="fw-bold">
                  {{ $t("Add the") }}
                  {{ $t(ordinalNumber[index]) }}
                  {{ $t("tenant") }}
                </h5>
              </div>
              <div class="col-md-12 pt-0">
                <div class="d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <div class="icon">
                      <Passport v-b-tooltip.hover title="Identity"></Passport>
                    </div>
                    <div class="icon ml-2">
                      <CreditCheckIcon v-b-tooltip.hover title="Income"></CreditCheckIcon>
                    </div>
                    <div class="icon ml-2">
                      <AMLIcon v-b-tooltip.hover title="KYC Check"></AMLIcon>
                    </div>
                    <div class="icon ml-2">
                      <MonthlyIncomeIcon
                        v-b-tooltip.hover
                        title="Credit Check"
                      ></MonthlyIncomeIcon>
                    </div>
                    <div class="ml-3"></div>
                  </div>
                  <div>
                    <button
                      class="btn btn-primary"
                      @click="$router.push('/user/selection')"
                    >
                      {{
                        $t(
                          anyTenantVerified ? "Verify next tenant" : "Start verification"
                        )
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Card>

          <TenantInfoCard
            v-for="(tenant, key) in user.tenants"
            :key="key"
            :tenant="tenant"
            @show-details="selectTenant"
            @delete-tenant="deleteTenantConfirm"
            @update-email="updateEmail"
          />

          <div class="guarantor" v-if="verifiedTenants >= 1">
            <div class="row p-4">
              <div class="col-md-12">
                <div class="col-md-12 pb-0">
                  <h5 class="fw-bold">Add a guarantor (optional)</h5>
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="d-flex col-md-12 col-lg-5">
                      <div
                        class="icon bg-primary"
                        v-if="guarantor && guarantor.identity_checked"
                      >
                        <Passport
                          v-b-tooltip.hover
                          title="Identity"
                          fill="#fff"
                        ></Passport>
                      </div>
                      <div class="icon" v-else>
                        <Passport v-b-tooltip.hover title="Identity"></Passport>
                      </div>
                      <div
                        class="icon ml-2 bg-primary"
                        v-if="guarantor && guarantor.income_checked"
                      >
                        <CreditCheckIcon
                          v-b-tooltip.hover
                          title="Income"
                          fill="#fff"
                        ></CreditCheckIcon>
                      </div>
                      <div class="icon ml-2" v-else>
                        <CreditCheckIcon
                          v-b-tooltip.hover
                          title="Income"
                        ></CreditCheckIcon>
                      </div>
                      <div
                        class="icon ml-2 bg-primary"
                        v-if="guarantor && guarantor.edr_data"
                      >
                        <MonthlyIncomeIcon
                          v-b-tooltip.hover
                          title="Credit Check"
                          fill="#fff"
                        ></MonthlyIncomeIcon>
                      </div>
                      <div class="icon ml-2" v-else>
                        <MonthlyIncomeIcon
                          v-b-tooltip.hover
                          title="Credit Check"
                        ></MonthlyIncomeIcon>
                      </div>
                      <div
                        class="icon ml-2 bg-primary"
                        v-if="guarantor && guarantor.aml_checked"
                      >
                        <AMLIcon
                          v-b-tooltip.hover
                          title="KYC Check"
                          fill="#fff"
                        ></AMLIcon>
                      </div>
                      <div class="icon ml-2" v-else>
                        <AMLIcon v-b-tooltip.hover title="KYC Check"></AMLIcon>
                      </div>

                      <div class="ml-3"></div>
                    </div>
                    <div
                      class="col-lg-7 col-md-12 text-right d-flex justify-content-right"
                    >
                      <div>
                        <b-button
                          variant="outline-primary"
                          @click="selectTenant(user.guarantor)"
                          v-if="
                            guarantor &&
                            guarantor.identity_checked &&
                            guarantor.credit_checked &&
                            guarantor.income_checked
                          "
                        >
                          {{ $t("Show details") }}
                        </b-button>
                        <button
                          class="btn btn-primary"
                          v-else-if="guarantor"
                          @click="$router.push(`/user/guarantor/1/${guarantor.id}`)"
                        >
                          {{ $t("Restart verification") }}
                        </button>
                        <button
                          class="btn btn-primary"
                          v-else
                          @click="$router.push(`/user/guarantor/1`)"
                        >
                          {{ $t("Start verification") }}
                        </button>
                      </div>
                      <div>
                        <b-button
                          v-if="guarantor"
                          class="ml-2"
                          variant="outline-danger"
                          @click="deleteTenantConfirm(guarantor)"
                        >
                          <i class="fa-regular fa-trash-can"></i>
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <b-form @submit.prevent="onSubmit">
            <Card cardClass="profile-card mb-4">
              <div class="row">
                <div class="col-md-12">
                  <h5 class="fw-bold">{{ $t("My household") }}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div>
                    {{ $t("Total persons in household") }}
                  </div>
                  <div class="fw-bold mt-1">
                    {{ user.totalPersons }}
                  </div>
                </div>
                <div class="col-md-4">
                  <div>
                    {{ $t("Gross monthly income") }}
                  </div>
                  <div class="fw-bold mt-1">
                    {{ grossMonthlyIncome | formatCurrency }}
                    <span v-if="user.guarantor">
                      (Guarantor
                      {{ user.guarantor.personalMonthlyIncome | formatCurrency }}
                      )
                    </span>
                  </div>
                </div>

                <div class="col-md-4">
                  <div>
                    {{ $t("Net monthly income") }}
                  </div>
                  <div class="fw-bold mt-1">
                    {{ user.net_monthly_income | formatCurrency }}
                  </div>
                </div>
              </div>
              <!-- After Qii -->
              <!-- <FormPartial partialName="household" :formFields="householdFormFields" /> -->
            </Card>
            <Card cardClass="profile-card" class="mb-4" id="preferences">
              <!-- <CardHeading :heading="$t('profile.preferences.heading')" class="mb-4" /> -->
              <div class="row">
                <div class="col-md-12">
                  <h5 class="fw-bold">{{ $t("Preferences") }}</h5>
                </div>
              </div>
              <FormPartial
                @input-with-name="inputChange"
                partialName="preferences"
                :submit="submitPartialData"
                @partialData="onPartialData"
                :formFields="preferencesFormFields"
              />

              <div class="row mt-4">
                <div class="col-md-6 pt-0" v-if="tenantsVerified">
                  <RouterLink to="/listings" class="btn btn-outline-primary btn-block">
                    {{ $t("View listings") }}
                  </RouterLink>
                </div>
                <div
                  class="col-md-6 pt-0"
                  :class="{
                    'col-md-6': tenantsVerified,
                    'col-md-12': !tenantsVerified,
                  }"
                >
                  <button
                    class="btn btn-primary btn-block"
                    :disabled="getLoadings.profile"
                    @mouseenter="submitPartialData = true"
                    @mouseleave="submitPartialData = false"
                  >
                    {{ $t("Save my profile") }}
                    <b-spinner
                      v-if="getLoadings.profile"
                      small
                      label="Spinning"
                      variant="white"
                    ></b-spinner>
                  </button>
                </div>
              </div>
            </Card>
          </b-form>

          <div>
            <div class="text-right text-gray4">MyID: {{ `1989${user.id}` }}</div>
          </div>

          <b-modal id="send-otp" ref="send-otp" hide-header hide-footer>
            <div class="text-center" v-if="tenant && !otpSent">
              <img src="@/assets/images/lock.png" style="width: 60px" alt="" />
              <h5 class="card__heading fw-bold text-primary mt-2">
                {{ $t("Unlock my personal details") }}
              </h5>
              <p class="px-3">
                {{
                  $t(
                    "We keep your personal details save. Want to view them? We will send a 6-digit code to:"
                  )
                }}
                <span class="text-info">{{ tenant.phone_number }}</span>
              </p>
              <p
                class="text-danger fw-bold text-capitalize mt-1"
                v-if="getErrors.sending_otp"
              >
                {{ getErrors.sending_otp }}
              </p>
              <button
                class="btn btn-primary btn-block"
                @click="sendVerificationCode"
                type="button"
                :disabled="getLoadings.sending_otp"
              >
                {{ $t("Send verification code") }}
                <b-spinner
                  v-if="getLoadings.sending_otp"
                  small
                  label="Spinning"
                  variant="white"
                ></b-spinner>
              </button>
            </div>
            <div class="text-center" v-if="otpSent">
              <img src="@/assets/images/lock.png" style="width: 60px" alt="" />
              <div class="text-center pt-2">
                <OTP @otpVerified="otpVerified" :tenant="tenant"></OTP>
              </div>
            </div>
          </b-modal>

          <TenantDetailModal
            @close="setTenant(null)"
            :tenant="tenant"
          ></TenantDetailModal>

          <TenantEmailUpdateModal
            :tenant="selected_tenant"
            @email-updated="getUserDetail"
          />
          <Modal
            modalId="delete-tenant"
            :no-close-on-backdrop="true"
            :hide-header="true"
            :hide-footer="true"
          >
            <div class="row">
              <div class="col-md-12">
                <h1>
                  {{ $t("Delete data of tenant") }}
                </h1>
                <p class="text-gray">
                  {{
                    $t(
                      "You are about delete the data of this tenant. You can start a new verification  for this tenant when the data is deleted."
                    )
                  }}
                </p>
                <div
                  class="alert alert-danger"
                  role="alert"
                  v-if="getErrors.delete_tenant"
                >
                  {{ getErrors.delete_tenant }}
                </div>
                <b-button
                  variant="danger"
                  @click="deleteTenantConfirm(delete_tenant, true)"
                  block
                  :disabled="getLoadings.delete_tenant"
                >
                  <b-spinner
                    v-if="getLoadings.delete_tenant"
                    small
                    label="Spinning"
                    variant="white"
                  ></b-spinner>
                  Delete data of tenant
                </b-button>
                <b-button
                  variant="outline-danger"
                  @click="$bvModal.hide('delete-tenant')"
                  block
                >
                  {{ $t("Cancel") }}
                </b-button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Card from "../../components/general/Card.vue";
import AMLIcon from "../../components/svg/AMLIcon.vue";
import CreditCheckIcon from "../../components/svg/CreditCheckIcon.vue";
import MonthlyIncomeIcon from "../../components/svg/MonthlyIncomeIcon.vue";
import Passport from "../../components/svg/Passport.vue";
import FormPartial from "../../components/ui/form/FormPartial.vue";
import preferredMunicipality from "@/constants/municipality";
import OTP from "@/components/Auth/OTP";
import Spinner from "@/components/ui/Spinner";
import TenantDetailModal from "@/components/Tenant/DetailModal";
import TenantEmailUpdateModal from "@/components/Tenant/EmailUpdateModal";
import TenantInfoCard from "@/components/Tenant/InfoCard";
import Modal from "../../components/ui/Modal.vue";

export default {
  components: {
    Card,
    FormPartial,
    Passport,
    AMLIcon,
    CreditCheckIcon,
    MonthlyIncomeIcon,
    OTP,
    Spinner,
    TenantDetailModal,
    TenantInfoCard,
    Modal,
    TenantEmailUpdateModal,
  },
  data() {
    return {
      formData: {
        tenants: [],
        householdComposition: "",
        pets: null,
        preferredHousingType: [],
        minLivingSpace: null,
        maxLivingSpace: null,
        minRooms: null,
        maxRooms: null,
        rent_period: null,
      },
      liveFormData: { personalMonthlyIncome: 0, tennentsIncomes: [] },
      submitPartialData: false,
      checklists: [],
      tenant_checklists: [],
      gettingUserDetail: false,
      ordinalNumber: ["", "first", "second", "third", "fourth"],
      delete_tenant: null,
      selected_tenant: null,
    };
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("auth", [
      "user_detail",
      "anyEntrepreneur",
      "user",
      "entrepreneurs",
      "notVerifiedEntrepreneurs",
      "guarantor",
    ]),
    ...mapGetters("tenant", ["tenant", "otpSent"]),
    tenantsVerified() {
      let verified = false;

      let tenants = this?.user?.tenants || [];

      if (tenants.length != this.user.totalPersons) {
        return false;
      }

      if (this?.user?.tenants?.length) {
        verified = true;
        for (let tenant of tenants) {
          if (tenant.expat) {
            verified =
              verified &&
              tenant.identity_checked &&
              tenant.income_checked &&
              tenant.aml_checked;
          } else {
            verified =
              verified &&
              tenant.identity_checked &&
              tenant.income_checked &&
              tenant.edr_data &&
              tenant.aml_checked;
          }
        }
      }

      return verified;
    },
    verifiedTenants() {
      let verified = 0;

      let tenants = this?.user?.tenants || [];

      for (let tenant of tenants) {
        if (tenant.expat) {
          if (tenant.identity_checked && tenant.income_checked && tenant.aml_checked) {
            verified++;
          }
        } else {
          if (
            tenant.identity_checked &&
            tenant.income_checked &&
            tenant.edr_data &&
            tenant.aml_checked
          ) {
            verified++;
          }
        }
      }

      console.log("verified", verified);

      return verified;
    },
    checkIncome() {
      for (let tenant of this.user.tenants) {
        if (tenant.cant_find_income == true && tenant.personalMonthlyIncome == null) {
          return false;
        }
      }
      return true;
    },
    anyTenantVerified() {
      if (!this.user || !this.user.tenants) {
        return false;
      }

      for (let t of this.user.tenants) {
        if (t.identity_checked && t.credit_checked && t.income_checked && t.aml_checked) {
          return true;
        }
      }

      return false;
    },
    grossMonthlyIncome() {
      let grossMonthlyIncome = 0;

      for (let tenant of this.user.tenants) {
        grossMonthlyIncome += tenant.personalMonthlyIncome
          ? Number(tenant.personalMonthlyIncome)
          : 0;
      }

      return grossMonthlyIncome;
    },
    householdFormFields() {
      const user = this.user;
      if (!user) {
        return [];
      }

      let grossMonthlyIncome = 0;

      for (let tenant of user.tenants) {
        grossMonthlyIncome += tenant.personalMonthlyIncome
          ? tenant.personalMonthlyIncome
          : 0;
      }

      console.log("grossMonthlyIncome", grossMonthlyIncome);
      return [
        {
          name: "totalPersons",
          type: "text",
          placeholder: this.$t("profile.household.totalPersons"),
          label: this.$t("profile.household.totalPersons"),
          component: "TextInput",
          value: user.totalPersons || null,
          classes: "col-6 py-0",
          // rules: "required|numeric",
          disabled: true,
        },

        {
          name: "grossMonthlyIncome",
          placeholder: this.$t("profile.household.income"),
          label: this.$t("profile.household.income"),
          component: "TextInput",
          value: grossMonthlyIncome.toString(),
          rules: "",
          classes: "col-6 py-0",
          disabled: true,
          masked: true,
          mask: [
            {
              mask: "€ num",
              blocks: {
                num: {
                  mask: Number, // enable number mask
                  scale: 2,
                  signed: false,
                  thousandsSeparator: ".",
                  padFractionalZeros: false,
                  normalizeZeros: true,
                  radix: ",",

                  // additional number interval options (e.g.)
                  min: 0,
                  max: 99999999,
                },
              },
            },
          ],
        },
      ];
    },
    preferencesFormFields() {
      const user = this.user;
      if (!user) {
        return [];
      }

      const propertyType = this.$t("singleListing.propertyType");
      let preferredHousingType = user.preferredHousingType
        ? user.preferredHousingType
        : [];
      return [
        {
          name: "preferredHousingType",
          component: "MultiselectInput",
          value: preferredHousingType.map((val) => ({
            text: propertyType[val],
            value: val,
          })),
          rules: "required",
          placeholder: this.$t("profile.preferences.housingType[0]"),
          label: this.$t("profile.preferences.housingType[0]"),
          classes: "col-12 py-0 notranslate",
          multiple: true,
          searchable: false,
          options: [
            {
              text: "Woning",
              value: "house",
            },
            {
              text: "Appartement",
              value: "appartment",
            },
            {
              text: "Studio",
              value: "studio",
            },
            {
              text: "Kamer",
              value: "room",
            },
          ],
        },
        {
          name: "rent_period",
          component: "SelectInput",
          value: user.rent_period,
          rules: "required",
          label: "Rent period",
          classes: "col-12 py-0 notranslate",
          multiple: false,
          searchable: false,
          options: [
            {
              text: "Undetermined",
              value: "undetermined",
            },
            {
              text: "Temporary",
              value: "temporary",
            },
          ],
        },
        {
          name: "rent_months",
          component: "TextInput",
          value: user.rent_months,
          rules: "required",
          label: "How many months do you want to rent?",
          placeholder: "How many months do you want to rent?",
          classes: "col-12 py-0 notranslate",
          hide: !(this.formData.rent_period == "temporary"),
        },
        {
          name: "householdComposition",
          component: "SelectInput",
          value: user.householdComposition || "",
          rules: "required",
          placeholder: this.$t("profile.household.composition"),
          label: this.$t("profile.household.composition"),
          classes: "col-12 py-0",
          options: [
            {
              text: this.$t("profile.household.composition"),
              value: "",
              disabled: true,
              hidden: true,
            },
            {
              text: this.$t("householdComposition[0]"),
              value: "single",
            },
            {
              text: this.$t("householdComposition[1]"),
              value: "couple",
            },
            {
              text: this.$t("householdComposition[2]"),
              value: "family",
            },
            {
              text: this.$t("householdComposition[3]"),
              value: "group occupancy",
            },
          ],
        },
        {
          name: "preferredMunicipality",
          placeholder: this.$t("profile.preferences.municipality"),
          label: this.$t("profile.preferences.municipality"),
          component: "MultiselectInput",
          rules: "required",
          value:
            user.preferredMunicipality && user.preferredMunicipality.length
              ? user.preferredMunicipality.map((val) => ({
                  text: val,
                  value: val,
                }))
              : [],
          classes: "col-12 py-0",
          multiple: true,
          searchable: true,
          options: preferredMunicipality,
        },
        {
          name: "minLivingSpace",
          type: "text",
          placeholder: this.$t("profile.preferences.minLS"),
          label: this.$t("profile.preferences.minLS"),
          component: "TextInput",
          value: user.minLivingSpace ? user.minLivingSpace : "",
          classes: "col-12 col-lg-6 py-0",
          rules: "required",
          masked: true,
          mask: [
            {
              mask: "",
            },
            {
              mask: "num m²",
              placeholderChar: "Min. living space",
              lazy: false,
              blocks: {
                num: {
                  mask: Number, // enable number mask
                  scale: 2,
                  signed: false,
                  thousandsSeparator: ".",
                  padFractionalZeros: false,
                  normalizeZeros: true,
                  radix: ",",
                  min: 0,
                  max: 99999999,
                },
              },
            },
          ],
        },
        {
          name: "maxLivingSpace",
          type: "text",
          placeholder: this.$t("profile.preferences.maxLS"),
          label: this.$t("profile.preferences.maxLS"),
          component: "TextInput",
          value: user.maxLivingSpace || "",
          classes: "col-12 col-lg-6 py-0",
          rules: "required",
          masked: true,
          mask: [
            {
              mask: "",
            },
            {
              mask: "num m²",
              lazy: false,
              blocks: {
                num: {
                  mask: Number, // enable number mask
                  scale: 2,
                  signed: false,
                  thousandsSeparator: ".",
                  padFractionalZeros: false,
                  normalizeZeros: true,
                  radix: ",",

                  // additional number interval options (e.g.)
                  min: 0,
                  max: 99999999,
                },
              },
            },
          ],
        },
        {
          name: "minRooms",
          type: "number",
          placeholder: this.$t("profile.preferences.minRooms"),
          label: this.$t("profile.preferences.minRooms"),
          component: "TextInput",
          value: user.minRooms || null,
          classes: "col-12 col-lg-6 py-0",
          rules: "required|numeric",
        },
        {
          name: "maxRooms",
          type: "number",
          placeholder: this.$t("profile.preferences.maxRooms"),
          label: this.$t("profile.preferences.maxRooms"),
          component: "TextInput",
          rules: "required|numeric",
          value: user.maxRooms || null,
          classes: "col-12 col-lg-6 py-0",
        },
        {
          name: "pets",
          component: "SelectInput",
          value: user.pets,
          rules: "required",
          placeholder: "Pets",
          label: "Pets",
          classes: "col-12 py-0",
          options: [
            {
              text: "With Animals",
              value: true,
            },
            {
              text: "Without Animals",
              value: false,
            },
          ],
        },
        {
          name: "number_of_pets",
          type: "number",
          placeholder: "Amount of pets",
          label: "Amount of pets",
          component: "TextInput",
          rules: "required|numeric",
          value: user.number_of_pets || null,
          classes: "col-12 col-lg-6 py-0",
          hide: this.formData.pets == false,
        },
        {
          name: "type_of_pets",
          type: "text",
          placeholder: "Type of pets",
          label: "Type of pets",
          component: "TextInput",
          rules: "required",
          value: user.type_of_pets || null,
          classes: "col-12 col-lg-6 py-0",
          hide: this.formData.pets == false,
        },
      ];
    },
  },
  mounted() {
    console.log("mounted");
    this.setTenant(null);
    // this.getUserDetail();
  },
  methods: {
    ...mapActions("tenant", [
      "updateTenant",
      "getTenant",
      "getTenantDetail",
      "sendOTP",
      "deleteTenant",
    ]),
    ...mapActions("user", ["updateUser"]),
    ...mapActions("auth", ["getUserDetail"]),
    ...mapMutations("tenant", ["setTenant"]),
    async selectTenant(tenant) {
      this.setTenant(tenant);
      try {
        await this.getTenantDetail(tenant.id);
        this.$refs["send-otp"].hide();
        this.$bvModal.show("tenant-modal");
      } catch (error) {
        console.log("Error", error);
        this.$refs["send-otp"].show();
      }
    },
    async deleteTenantConfirm(tenant, confirm = false) {
      console.log("deleteTenant", tenant);
      if (!confirm) {
        this.$bvModal.show("delete-tenant");
        this.delete_tenant = tenant;
        return;
      }

      await this.deleteTenant(this.delete_tenant.id);
      this.getUserDetail();
    },
    async updateEmail(tenant, confirm = false) {
      this.setTenant(tenant);
      if (!confirm) {
        this.$bvModal.show("update-tenant-email");
        this.selected_tenant = tenant;
        return;
      }
    },
    async sendVerificationCode() {
      await this.sendOTP();
    },
    otpVerified() {
      console.log("otpVerified");
      this.selectTenant(this.tenant);
    },
    async onSubmit() {
      this.submitPartialData = false;
      // this.$router.push("/listings");
      await this.updateUser({ body: this.formData });
      this.$bvToast.toast("Your changes have been saved successfully!", {
        title: `Success`,
        variant: "success",
        toaster: "b-toaster-bottom-right",
        solid: true,
      });
      this.getUserDetail();
    },
    onPartialData(data, i) {
      console.log("data", data, i);
      if (data.partialName === "tennant") {
        if (this.formData.tenants[i]) {
          return (this.formData.tenants[i] = {
            ...this.formData.tenants[i],
            ...data.formData,
          });
        }
      }
      for (let key in data.formData) {
        if (key === "preferredHousingType" || key === "preferredMunicipality") {
          this.formData[key] = data.formData[key].map((pref) => pref.value);
          continue;
        }
        if (key === "phoneNumber") {
          this.formData[key] = data.formData[key];
          continue;
        }
        this.formData[key] = isNaN(data.formData[key])
          ? data.formData[key]
          : +data.formData[key];
      }
    },
    inputChange(data) {
      this.formData[data.name] = data.value;
    },
  },
};
</script>
<style lang="scss" scoped>
.icon {
  background: #eaebed;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  display: flex;
  svg {
    margin: auto;
  }
}

.guarantor {
  border: 1.5px dashed #c8c8c8;
  border-radius: 5px;
}
</style>
