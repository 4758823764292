<template>
  <ValidationProvider
    :name="name"
    :vid="name"
    :rules="rules"
    v-slot="{ valid, errors }"
    :classes="false"
  >
    <label v-if="label">{{ label }}</label>
    <label v-if="title">{{ title }}</label>
    <b-form-group>
      <b-form-radio-group
        v-bind="$attrs"
        :state="errors[0] ? false : valid && validate ? true : null"
        v-model="innerValue"
        :options="options"
      >
      </b-form-radio-group>

      <b-form-invalid-feedback
        v-if="validate"
        :state="errors[0] ? false : valid && validate ? true : null"
        id="inputLiveFeedback"
        >{{ errors[0] }}</b-form-invalid-feedback
      >
    </b-form-group>
  </ValidationProvider>
</template>

<script>
import inputMixin from "@/mixins/inputMixin";
import radioMixin from "@/mixins/radioMixin";

export default {
  mixins: [inputMixin, radioMixin],
  props: {
    title: {
      type: String,
    },
    label: {
      type: String,
    },
  },
  watch: {
    innerValue(newVal) {
      this.$emit("update-field", { newVal, name: this.name });
      this.$emit("input-with-name", { value: newVal, name: this.name });
      this.$emit("update:modelValue", newVal);
      this.$emit("input", newVal);
    },
  },
  created() {
    this.innerValue = this.value;
  },
};
</script>
