<template>
  <Card class="profile-card mb-3">
    <div class="row">
      <div class="col-md-12 pb-0">
        <h5 class="fw-bold" v-if="tenant.name && tenant.name.trim()">
          {{ tenant.name }}
        </h5>
        <h5 class="fw-bold" v-else-if="sequence">
          {{ $t("Add the") }}
          {{ $t(ordinalNumber[sequence + 1]) }}
          {{ $t("tenant") }}
        </h5>
        <router-link
          :to="{ name: 'EntrepreneurDocumentUpload', params: { tenant_id: tenant.id } }"
          class="text-info"
          v-if="userType == 'user' && tenant.document_uploaded === false"
        >
          Upload documents
        </router-link>

        <div
          class="text-info cursor-pointer"
          v-if="userType == 'user'"
          @click="$emit('update-email', tenant)"
        >
          <span v-if="tenant.email">
            {{ tenant.email }}
          </span>
          <span v-else> Add extra email address </span>
        </div>
      </div>

      <div class="col-md-12">
        <div class="row">
          <div class="d-flex col-md-12 col-lg-5">
            <div class="icon bg-primary" v-if="tenant.identity_checked">
              <Passport v-b-tooltip.hover title="Identity" fill="#fff"></Passport>
            </div>
            <div class="icon" v-else>
              <Passport v-b-tooltip.hover title="Identity"></Passport>
            </div>
            <div class="icon ml-2 bg-primary" v-if="tenant.income_checked">
              <CreditCheckIcon
                v-b-tooltip.hover
                title="Income"
                fill="#fff"
              ></CreditCheckIcon>
            </div>
            <div class="icon ml-2" v-else>
              <CreditCheckIcon v-b-tooltip.hover title="Income"></CreditCheckIcon>
            </div>
            <div class="icon ml-2 bg-primary" v-if="tenant.edr_data">
              <MonthlyIncomeIcon
                v-b-tooltip.hover
                title="Credit Check"
                fill="#fff"
              ></MonthlyIncomeIcon>
            </div>
            <div class="icon ml-2" v-else>
              <MonthlyIncomeIcon
                v-b-tooltip.hover
                title="Credit Check"
              ></MonthlyIncomeIcon>
            </div>
            <div class="icon ml-2 bg-primary" v-if="tenant.aml_checked">
              <AMLIcon v-b-tooltip.hover title="KYC Check" fill="#fff"></AMLIcon>
            </div>
            <div class="icon ml-2" v-else>
              <AMLIcon v-b-tooltip.hover title="KYC Check"></AMLIcon>
            </div>

            <div class="ml-3"></div>
          </div>
          <div class="col-lg-7 col-md-12 text-right d-flex justify-content-right">
            <div>
              <b-button
                variant="outline-primary"
                @click="$emit('show-details', tenant)"
                v-if="
                  userType == 'broker' ||
                  (tenant.identity_checked &&
                    tenant.credit_checked &&
                    tenant.income_checked)
                "
              >
                {{ $t("Show details") }}
              </b-button>
              <button class="btn btn-primary" v-else @click="verifyTenant">
                {{ $t("Restart verification") }}
              </button>
            </div>
            <div>
              <b-button
                class="ml-2"
                variant="outline-danger"
                v-if="userType == 'user'"
                @click="$emit('delete-tenant', tenant)"
              >
                <i class="fa-regular fa-trash-can"></i>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Card>
</template>
<script>
import { mapGetters } from "vuex";
import Card from "../general/Card.vue";
import AMLIcon from "../svg/AMLIcon.vue";
import CreditCheckIcon from "../svg/CreditCheckIcon.vue";
import MonthlyIncomeIcon from "../svg/MonthlyIncomeIcon.vue";
import Passport from "../svg/Passport.vue";

export default {
  name: "TenantInfoCard",
  props: ["tenant", "sequence"],
  data() {
    return {
      ordinalNumber: ["", "first", "second", "third", "fourth"],
    };
  },
  computed: {
    ...mapGetters("auth", ["userType"]),
  },
  components: { Passport, CreditCheckIcon, MonthlyIncomeIcon, AMLIcon, Card },
  methods: {
    verifyTenant() {
      console.log("verifyTenant", this.tenant);
      if (this.tenant.expat == null) {
        console.log("verifyTenant 1");
        return this.$router.push(`/user/selection/${this.tenant.id}`);
      }

      if (!this.tenant.profession) {
        console.log("verifyTenant 2");
        return this.$router.push(`/user/income/1/${this.tenant.id}`);
      }

      if (this.tenant.profession == "entrepreneur" && !this.tenant.professional_years) {
        console.log("verifyTenant 3");
        return this.$router.push(`/user/income/2/${this.tenant.id}`);
      }

      if (!this.tenant.phone_number || !this.tenant.phone_number_verified_at) {
        console.log("verifyTenant 4");
        return this.$router.push({
          name: "ExpatStep1",
          params: { tenant_id: this.tenant.id },
        });
      }

      if (this.tenant.expat == 0) {
        if (!this.tenant.digid_data) {
          return this.$router.push({
            name: "Digid",
            params: { tenant_id: this.tenant.id },
          });
        }

        if (this.tenant.profession == "entrepreneur" && !this.tenant.nordigen_data) {
          return this.$router.push({
            name: "ExpatStep7",
            params: { tenant_id: this.tenant.id },
          });
        }

        if (
          this.tenant.profession !== "entrepreneur" &&
          !this.tenant.uwv_data &&
          !this.tenant.nordigen_data
        ) {
          return this.$router.push({
            name: "DigidUWV",
            params: { tenant_id: this.tenant.id },
          });
        }
      } else {
        if (!this.tenant.country) {
          console.log("verifyTenant 5");
          return this.$router.push({
            name: "ExpatStep3",
            params: { tenant_id: this.tenant.id },
          });
        }

        if (!this.tenant.dob) {
          console.log("verifyTenant 6");
          return this.$router.push({
            name: "ExpatStep4",
            params: { tenant_id: this.tenant.id },
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  background: #eaebed;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  display: flex;
  svg {
    margin: auto;
  }
}
</style>
