<template>
  <BRow>
    <BCol :class="[classes]" v-for="{
      classes,
      name,
      label,
      component,
      disabled,
      options = [],
      datalist = [],
      ...attrs
    } in filteredFormFields" :key="name">
      <component v-on="$listeners" v-if="component === 'RadioInput'" :is="component" :name="name" :label="label"
        v-bind="attrs" @update-field="updateField" :disabled="disabled"
        :options="options && options.length ? options : null">
        <template v-if="options.length">
          <option v-for="({ value, text, ...rest }, i) in options" v-bind="rest" :key="i" :value="value">
            {{ text }}
          </option>
        </template>
      </component>
      <component v-else v-on="$listeners" :is="component" :name="name" :label="label" v-bind="attrs"
        v-model="formData[name]" :disabled="disabled" :options="options && options.length ? options : null"
        :datalist="datalist ? datalist : []">
        <template v-if="options.length">
          <option v-for="({ value, text, ...rest }, i) in options" v-bind="rest" :key="i" :value="value">
            {{ text }}
          </option>
        </template>
      </component>
    </BCol>
  </BRow>
</template>

<script>
import TextInput from "@/components/ui/form/controls/TextInput.vue";
import Datepicker from "@/components/ui/form/controls/Datepicker.vue";
import FileInput from "@/components/ui/form/controls/FileInput.vue";
import SelectInput from "@/components/ui/form/controls/SelectInput.vue";
import MultiselectInput from "@/components/ui/form/controls/MultiSelectInput.vue";
import RadioWithInput from "@/components/ui/form/controls/RadioWithInput.vue";
import liveValuesMixin from "@/mixins/liveValuesMixin";
import RadioInput from "@/components/ui/form/controls/RadioInput.vue";
import RichTextEditor from "@/components/ui/form/controls/RichTextEditor.vue";
export default {
  props: {
    submit: {
      type: Boolean,
      default: false,
    },
    formFields: {
      type: Array,
      required: true,
    },
    partialName: {
      type: String,
      required: true,
    },
    resetForm: {
      type: Boolean,
      default: false,
    },

    edit: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TextInput,
    Datepicker,
    FileInput,
    SelectInput,
    MultiselectInput,
    RadioWithInput,
    RadioInput,
    RichTextEditor
  },
  data() {
    return {
      formData: {},
    };
  },
  mixins: [liveValuesMixin],
  mounted() {
    this.formFields.forEach((field) => {
      this.$set(this.formData, field.name, field.value)
    });

    this.addLiveValuesWatchers(this.emitLiveValuesOf);
  },
  methods: {
    updateField(data) {
      const { newVal, name } = data;
      this.formData[name] = newVal;
    },
  },
  computed: {
    filteredFormFields() {
      return this.formFields.filter((field) => !field.hide);
    },
  },
  watch: {
    submit(newVal) {
      if (newVal) {
        this.$emit("partialData", {
          formData: this.formData,
          partialName: this.partialName,
        });
      }
    },
    resetForm(newVal) {
      if (newVal) {
        this.formFields.forEach((field) =>
          this.$set(this.formData, field.name, field.defaultValue)
        );
      }
    },
    // formFields(fields) {
    //   console.log("fields", fields)
    //   fields.forEach((field) =>
    //     this.$set(this.formData, field.name, field.defaultValue)
    //   );
    // },
    liveValues: {
      deep: true,
      handler(newVal) {
        if (newVal.grossMonthlyIncome) {
          this.formData.grossMonthlyIncome = newVal.grossMonthlyIncome;
        }
      },
    },
  },
};

</script>

<style lang="scss" scoped>

</style>
