<template>
  <div>
    <h5 class="card__heading fw-bold text-primary mt-2">
      {{ $t("Enter the 6 digit code") }}
    </h5>
    <p class="px-3 text-gray">
      {{ $t("We have sent the code to:") }}
      <span class="text-info" v-if="tenant">{{ tenant.phone_number }}</span>
      <span class="text-info" v-else-if="user">{{ user.phoneNumber }}</span>
    </p>
    <div class="d-flex justify-content-between">
      <input
        type="number"
        maxlength="1"
        class="otp"
        v-model="otp1"
        id="otp1"
        @keyup="focusOTP($event, 1)"
      />
      <input
        type="number"
        maxlength="1"
        class="otp"
        v-model="otp2"
        id="otp2"
        @keyup="focusOTP($event, 2)"
      />
      <input
        type="number"
        maxlength="1"
        class="otp"
        v-model="otp3"
        id="otp3"
        @keyup="focusOTP($event, 3)"
      />
      <div class="desh">-</div>
      <input
        type="number"
        maxlength="1"
        class="otp"
        v-model="otp4"
        id="otp4"
        @keyup="focusOTP($event, 4)"
      />
      <input
        type="number"
        maxlength="1"
        class="otp"
        v-model="otp5"
        id="otp5"
        @keyup="focusOTP($event, 5)"
      />
      <input
        type="number"
        maxlength="1"
        class="otp"
        v-model="otp6"
        id="otp6"
        @keyup="focusOTP($event, 6)"
      />
    </div>

    <p class="text-danger fw-bold text-capitalize mt-3 mb-0" v-if="getErrors.verify_otp">
      {{ getErrors.verify_otp }}
    </p>
    <p class="text-danger fw-bold text-capitalize mt-3 mb-0" v-if="getErrors.sending_otp">
      {{ getErrors.sending_otp }}
    </p>

    <div class="my-4 d-flex justify-content-center">
      <div class="fw-medium text-gray">
        {{ $t("No text received") }}
      </div>
      <div class="ml-1">
        <b-spinner
          v-if="getLoadings.sending_otp"
          small
          label="Spinning"
          variant="primary"
          class="mx-2"
        >
        </b-spinner>
        <a href="javascript:void()" class="text-gray fw-bold" @click="resendOTP">
          {{ $t("Resend") }}
        </a>
      </div>
    </div>

    <button
      class="btn btn-primary btn-block"
      @click="verifyCode"
      :disabled="getLoadings.verify_otp"
      :class="{ disabled: otp.length != 7 }"
    >
      {{ $t("Unlock my personal details") }}
      <b-spinner
        v-if="getLoadings.verify_otp"
        small
        label="Spinning"
        variant="white"
      ></b-spinner>
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "OTP",
  props: ["tenant"],
  data() {
    return {
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",
      form: {
        phone_number: "",
      },
      submitting: false,
    };
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("auth", ["user"]),
    otp() {
      return `${this.otp1}${this.otp2}${this.otp3}-${this.otp4}${this.otp5}${this.otp6}`;
    },
  },
  methods: {
    ...mapActions("auth", ["verifyOTP", "sendOTP"]),
    ...mapActions("tenant", ["verifyOTP"]),
    focusOTP($event, current) {
      if ($event.code == "Backspace") {
        current--;
      } else {
        current++;
      }

      if (current >= 1 && current <= 6) {
        document.getElementById("otp" + current).focus();
      }
    },
    async resendOTP() {
      console.log("resendOTP");
      if (this.tenant) {
        await this.$store.dispatch("tenant/sendOTP");
      } else {
        await this.sendOTP();
      }
    },
    async verifyCode() {
      if (this.tenant) {
        await this.$store.dispatch("tenant/verifyOTP", this.otp);
      } else {
        await this.verifyOTP(this.otp);
      }
      this.$emit("otpVerified");
    },
  },
};
</script>
<style lang="scss" scoped>
input.otp {
  width: 40px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #dedede;
  margin-right: 10px;
  text-align: center;
}
</style>
