var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BRow',_vm._l((_vm.filteredFormFields),function({
    classes,
    name,
    label,
    component,
    disabled,
    options = [],
    datalist = [],
    ...attrs
  }){return _c('BCol',{key:name,class:[classes]},[(component === 'RadioInput')?_c(component,_vm._g(_vm._b({tag:"component",attrs:{"name":name,"label":label,"disabled":disabled,"options":options && options.length ? options : null},on:{"update-field":_vm.updateField}},'component',attrs,false),_vm.$listeners),[(options.length)?_vm._l((options),function({ value, text, ...rest },i){return _c('option',_vm._b({key:i,domProps:{"value":value}},'option',rest,false),[_vm._v(" "+_vm._s(text)+" ")])}):_vm._e()],2):_c(component,_vm._g(_vm._b({tag:"component",attrs:{"name":name,"label":label,"disabled":disabled,"options":options && options.length ? options : null,"datalist":datalist ? datalist : []},model:{value:(_vm.formData[name]),callback:function ($$v) {_vm.$set(_vm.formData, name, $$v)},expression:"formData[name]"}},'component',attrs,false),_vm.$listeners),[(options.length)?_vm._l((options),function({ value, text, ...rest },i){return _c('option',_vm._b({key:i,domProps:{"value":value}},'option',rest,false),[_vm._v(" "+_vm._s(text)+" ")])}):_vm._e()],2)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }