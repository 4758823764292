export default {
  data() {
    return {
      innerValue: "",
    };
  },

  watch: {
    value(newVal) {
      if(newVal != null) return this.innerValue = newVal;
      this.innerValue = ''
    },

  },

};
