export default {
  props: {
    emitLiveValuesOf: {
      type: Array,
      default() {
        return [];
      },
    },
    liveValues: {
      type: Object,
      default: null,
    },
    addWatcherOnce:{
      type:Boolean,
      default:true
    }
  },
  methods: {
    addLiveValuesWatchers(liveValuesArr) {
      if (liveValuesArr.length) {
        liveValuesArr.forEach((val) => {
          this.$watch(`formData.${val}`, function (newVal) {
            this.$emit("liveValueUpdate", { [val]: newVal });
          });
        });
      }
    },
  },
  watch:{
    emitLiveValuesOf(newVal){
      if(!this.addWatcherOnce){
        this.addLiveValuesWatchers(newVal)
      }
    
    }
  }
};
