
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4 16L4 20H8V22H2L2 16H4ZM22 16V22H16V20H20V16H22ZM7.5 7C7.50002 8.01984 7.8463 9.00946 8.48215 9.80682C9.118 10.6042 10.0057 11.162 11 11.389V17H13L13.001 11.388C13.9949 11.1609 14.8823 10.6031 15.5179 9.80595C16.1536 9.00882 16.4998 8.01954 16.5 7H18.5C18.5002 8.18939 18.1741 9.35605 17.5571 10.3729C16.9402 11.3898 16.056 12.2179 15.001 12.767L15 19L9 19L9 12.768C7.94462 12.2189 7.06018 11.3907 6.44303 10.3737C5.82587 9.3566 5.49969 8.18967 5.5 7H7.5ZM12 5C12.663 5 13.2989 5.26339 13.7678 5.73223C14.2366 6.20107 14.5 6.83696 14.5 7.5C14.5 8.16304 14.2366 8.79893 13.7678 9.26777C13.2989 9.73661 12.663 10 12 10C11.337 10 10.7011 9.73661 10.2322 9.26777C9.76339 8.79893 9.5 8.16304 9.5 7.5C9.5 6.83696 9.76339 6.20107 10.2322 5.73223C10.7011 5.26339 11.337 5 12 5ZM8 2V4L4 3.999L4 8H2L2 2L8 2ZM22 2V8L20 8V4L16 4V2L22 2Z"
            :fill="fill" />
    </svg>

    
</template>
  
<script>
export default {
    props: {
        fill: {
            type: String,
            default: "#93939C",
        },
    },
};
</script>
  