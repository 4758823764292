<template>
  <div class="radio-with-input">
    <p class="fs-18 fw-bold" v-if="title">{{ title }}</p>
    <ValidationProvider
      ref="provider"
      :name="name"
      :vid="name"
      :rules="rules"
      v-slot="{ valid, errors }"
    >
      <b-form-group
        v-slot="{ ariaDescribedby }"
        :state="errors[0] ? false : valid && validate ? true : null"
      >
        <b-form-radio-group v-model="innerValue">
          <b-form-radio
            v-for="option in options"
            :key="option.value"
            :aria-describedby="ariaDescribedby"
            :value="option.value"
          >
            <ValidationProvider
              v-if="option.textInput"
              :name="name"
              :vid="name"
              :rules="innerValue === option.value ? rules : ''"
              v-slot="{ valid, errors }"
            >
              <ImaskInput
                @focus="innerValue = option.value"
                class="form-control"
                :class="[
                  errors[0]
                    ? 'is-invalid'
                    : valid && validate
                    ? 'is-valid'
                    : null,
                ]"
                v-model="input"
                :mask="option.input.mask"
                :defaultValue="option.input.defaultValue"
                placeholder=" "
              />
            </ValidationProvider>
            <template v-else>
              {{ option.text }}
            </template>
          </b-form-radio>
        </b-form-radio-group>
        <b-form-invalid-feedback
          v-if="validate"
          :state="errors[0] ? false : valid && validate ? true : null"
          id="inputLiveFeedback"
          >{{ errors[0] }}</b-form-invalid-feedback
        >
      </b-form-group>
    </ValidationProvider>
  </div>
</template>

<script>
import inputMixin from "@/mixins/inputMixin";
import radioMixin from "@/mixins/radioMixin";
import { IMaskComponent } from "vue-imask";
export default {
  mixins: [inputMixin, radioMixin],
  components: {
    ImaskInput: IMaskComponent,
  },
  props: {
    title: {
      type: String,
    },
  },
  data() {
    return {
      input: "",
    };
  },
  watch: {
    input(newVal) {
      this.$emit("update-field", { newVal, name: this.name });
    },
    async innerValue(newVal) {
      let val = newVal;
      if (this.$attrs.textInputRadios.indexOf(newVal) > -1) val = this.input;
      this.$emit("update-field", { newVal: val, name: this.name });
    },
  },
  created() {
    this.innerValue = this.value;
    if (this.$attrs.textInputRadios.indexOf(this.value) > -1) {
      const option = this.options.find((op) => op.value === this.value);

      if (option) {
        this.input = option.input.value;
      }
    }
  },
};
</script>

<style lang="scss">
.radio-with-input {
  div[role="radiogroup"] {
    display: flex;
  }
  .custom-control-inline {
    display: flex;
    align-items: center;
  }
  .custom-radio .custom-control-label::before {
    top: 50%;
    transform: translateY(-50%);
  }
  .custom-control-label::after {
    top: 50%;

    transform: translateY(-50%);
  }
}
</style>
