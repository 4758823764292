<template>
  <ValidationProvider :vid="name" :name="name" :rules="rules" v-slot="{ valid, errors }">
    <template v-if="label">
      <label class="dual-label" v-if="typeof label === 'string'" :for="name">{{
        label
      }}</label>
      <label class="dual-label" v-else :for="name">
        <span>
          {{ label.main }}
        </span>
        <span>
          {{ label.secondary }}
        </span>
      </label>
    </template>
    <div class="notranslate">
      <vue-editor
        :class="{ invalid: !valid }"
        :value="value"
        v-bind="$attrs"
        v-on:input="onInput"
      ></vue-editor>
    </div>

    <small class="text-danger" v-if="errors.length">{{ errors[0] }}</small>
  </ValidationProvider>
</template>

<script>
import inputMixin from "@/mixins/inputMixin";
import { VueEditor } from "vue2-editor";
export default {
  mixins: [inputMixin],
  components: {
    VueEditor,
  },
  props: {
    label: {
      type: [String, Object],
    },
  },
};
</script>

<style lang="scss">
.ql-toolbar.ql-snow {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-color: $border-color;
}
.ql-container.ql-snow {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-color: $border-color;
}
.broker__addlistings__inputData__textarea {
  height: 365px;
}
</style>
