var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"radio-with-input"},[(_vm.title)?_c('p',{staticClass:"fs-18 fw-bold"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('ValidationProvider',{ref:"provider",attrs:{"name":_vm.name,"vid":_vm.name,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function({ valid, errors }){return [_c('b-form-group',{attrs:{"state":errors[0] ? false : valid && _vm.validate ? true : null},scopedSlots:_vm._u([{key:"default",fn:function({ ariaDescribedby }){return [_c('b-form-radio-group',{model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},_vm._l((_vm.options),function(option){return _c('b-form-radio',{key:option.value,attrs:{"aria-describedby":ariaDescribedby,"value":option.value}},[(option.textInput)?_c('ValidationProvider',{attrs:{"name":_vm.name,"vid":_vm.name,"rules":_vm.innerValue === option.value ? _vm.rules : ''},scopedSlots:_vm._u([{key:"default",fn:function({ valid, errors }){return [_c('ImaskInput',{staticClass:"form-control",class:[
                errors[0]
                  ? 'is-invalid'
                  : valid && _vm.validate
                  ? 'is-valid'
                  : null,
              ],attrs:{"mask":option.input.mask,"defaultValue":option.input.defaultValue,"placeholder":" "},on:{"focus":function($event){_vm.innerValue = option.value}},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}})]}}],null,true)}):[_vm._v(" "+_vm._s(option.text)+" ")]],2)}),1),(_vm.validate)?_c('b-form-invalid-feedback',{attrs:{"state":errors[0] ? false : valid && _vm.validate ? true : null,"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }