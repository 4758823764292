<template>
  <ValidationProvider
    :rules="rules"
    v-slot="{ valid, errors }"
    :vid="name"
    :name="name"
    ref="provider"
  >
    <label :class="[{ invalid: !valid }, customClass]" class="file-input">
      <span :class="`${customClass}__text`">
        {{ $attrs.text }}
      </span>
      <svg
        width="49"
        height="66"
        viewBox="0 0 49 66"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28.5833 17.5312V0H3.0625C1.36536 0 0 1.3793 0 3.09375V62.9062C0 64.6207 1.36536 66 3.0625 66H45.9375C47.6346 66 49 64.6207 49 62.9062V20.625H31.6458C29.9615 20.625 28.5833 19.2328 28.5833 17.5312ZM36.9006 45.3763H28.5833V55.6888C28.5833 56.8283 27.6697 57.7513 26.5417 57.7513H22.4583C21.3303 57.7513 20.4167 56.8283 20.4167 55.6888V45.3763H12.0994C10.2772 45.3763 9.36742 43.1475 10.6613 41.8494L22.9649 29.5131C23.8135 28.661 25.184 28.661 26.0325 29.5131L38.3361 41.8494C39.6313 43.1475 38.7228 45.3763 36.9006 45.3763ZM48.1068 13.5352L35.6143 0.902344C35.0401 0.322266 34.2617 0 33.4451 0H32.6667V16.5H49V15.7137C49 14.9016 48.681 14.1152 48.1068 13.5352Z"
          fill="#BFBFBF"
        />
      </svg>
      <input
        ref="fileInput"
        type="file"
        class="d-none"
        v-bind="$attrs"
        @change="onChange"
      />
    </label>
    <div class="d-flex  flex-wrap" v-if="previewImgs && previewImgs.length">
      <img
        v-for="(previewImg, i) in previewImgs"
        :key="i"
        class="file-input__preview img-fluid mx-1"
        :src="previewImg"
        alt="preview"
      />
    </div>
    <small v-if="!valid" class="text-danger">{{ errors[0] }}</small>

  </ValidationProvider>
</template>

<script>
import inputMixin from "@/mixins/inputMixin";

export default {
  mixins: [inputMixin],
  props: {
    preview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      previewImgs: [],
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  methods: {
    async onChange(e) {
      const { valid } = await this.$refs.provider.validate(e);
      if(!valid) return
      if (this.preview) {
        this.previewImgs = [];
        let input = this.$refs.fileInput;
        let files = input.files;
        const filesLength = input.files.length;
        if (filesLength) {
          for (let i = 0; i < filesLength; i++) {
            let reader = new FileReader();
            reader.onload = (evt) => {
              this.previewImgs.push(evt.target.result);
            };
            reader.readAsDataURL(files[i]);
          }
        }
      }

      this.$emit("change", e.target.files);
    },
  },
};
</script>

<style lang="scss" scoped>
.file-input {
  background: $body-bg;
  padding: 0.9375rem 5rem;
  &__preview{
    max-width: 100px;
  }
}

.broker__profile__fileinputContainer,
.stackedFileInput {
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  padding-bottom: 18px !important;
  padding-top: 18px !important;
  &__text {
    @include font(1.125rem, #7c7c7c, 400);
    padding-bottom: 20px !important;
  }
}
</style>
