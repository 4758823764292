<template>
  <b-modal id="tenant-modal" ref="tenant-modal" hide-footer @hidden="$emit('close')">
    <div class="row p-4" v-if="tenant">
      <b-col cols="12">
        <h1>
          {{ $t("My personal details") }}
        </h1>
      </b-col>
      <div class="col-md-6">
        <div>
          <label>{{ $t("First name") }}</label>
          <div>
            <strong>{{ tenant.first_name }}</strong>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div>
          <label>{{ $t("Last name") }}</label>
          <div>
            <strong>{{ tenant.last_name }}</strong>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <label>{{ $t("Date of birth") }}</label>
        <div>
          <strong>{{ tenant.dob | moment("DD-MM-YYYY") }}</strong>
        </div>
      </div>
      <div class="col-md-6">
        <label>{{ $t("Phonenumber") }}</label>
        <div>
          <strong>{{ tenant.phone_number }}</strong>
        </div>
      </div>
      <div class="col-md-6">
        <label>{{ $t("Gross income") }}</label>
        <div>
          <strong>€{{ tenant.personalMonthlyIncome }}</strong>
        </div>
      </div>
      <div class="col-md-6">
        <label>{{ $t("Net income") }}</label>
        <div>
          <strong>€{{ tenant.net_income }}</strong>
        </div>
      </div>
      <div class="col-md-12">
        <label>{{ $t("Address") }}</label>
        <div>
          <strong>{{ tenant.address }}</strong>
        </div>
      </div>
      <div class="col-md-12">
        <div class="d-flex mb-4 align-items-center">
          <div class="icon bg-primary" v-if="tenant.identity_checked">
            <Passport v-b-tooltip.hover title="Identity" fill="#fff"></Passport>
          </div>
          <div class="icon" v-else>
            <Passport v-b-tooltip.hover title="Identity"></Passport>
          </div>
          <div class="ml-3">
            <strong>{{ $t("Passport selfie check") }}</strong>
            <p class="fs-14px text-muted mb-0">Identity verification succesfull</p>
          </div>
        </div>
        <div class="d-flex mb-4 align-items-center">
          <div class="icon bg-primary" v-if="tenant.income_checked">
            <CreditCheckIcon
              v-b-tooltip.hover
              title="Income"
              fill="#fff"
            ></CreditCheckIcon>
          </div>
          <div class="icon" v-else>
            <CreditCheckIcon v-b-tooltip.hover title="Income"></CreditCheckIcon>
          </div>
          <div class="ml-3">
            <strong>{{ $t("Specify monthly income") }}</strong>
            <p class="fs-14px text-muted mb-0">
              {{ $t("Monthly income: ") }}
              {{ `€${tenant.personalMonthlyIncome}` }}
            </p>
          </div>
        </div>

        <div class="d-flex mb-4 align-items-center">
          <div class="icon bg-primary" v-if="tenant.edr_data">
            <MonthlyIncomeIcon
              v-b-tooltip.hover
              title="Credit Check"
              fill="#fff"
            ></MonthlyIncomeIcon>
          </div>
          <div class="icon" v-else>
            <MonthlyIncomeIcon v-b-tooltip.hover title="Credit Check"></MonthlyIncomeIcon>
          </div>
          <div class="ml-3">
            <strong>{{ $t("Creditcheck") }}</strong>
            <p class="fs-14px text-muted mb-0">Identity verification succesfull</p>
          </div>
        </div>

        <div class="d-flex mb-4 align-items-center">
          <div class="icon bg-primary" v-if="tenant.aml_checked">
            <AMLIcon v-b-tooltip.hover title="KYC Check" fill="#fff"></AMLIcon>
          </div>
          <div class="icon" v-else>
            <AMLIcon v-b-tooltip.hover title="KYC Check"></AMLIcon>
          </div>
          <div class="ml-3">
            <strong>{{ $t("AML - Check") }}</strong>
            <p class="fs-14px text-muted mb-0">AML verification succesfull</p>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import AMLIcon from "../svg/AMLIcon.vue";
import CreditCheckIcon from "../svg/CreditCheckIcon.vue";
import MonthlyIncomeIcon from "../svg/MonthlyIncomeIcon.vue";
import Passport from "../svg/Passport.vue";

export default {
  name: "TenantDetailModal",
  props: ["tenant"],
  components: { Passport, CreditCheckIcon, MonthlyIncomeIcon, AMLIcon },
  mounted() {
    // this.$refs["tenant-modal"].show();
  },
};
</script>

<style lang="scss" scoped>
.icon {
  background: #eaebed;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  display: flex;
  svg {
    margin: auto;
  }
}
</style>
