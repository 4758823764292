<template>
  <Modal
    modalId="update-tenant-email"
    :no-close-on-backdrop="false"
    :hide-header="true"
    :hide-footer="true"
  >
    <div class="row">
      <div class="col-md-12">
        <h1>
          {{ $t("Add extra email") }}
        </h1>
        <p class="text-gray">
          {{
            $t(
              "Do you want to keep other tenants updated? Add the email address below. It's not possible to login with this email address."
            )
          }}
        </p>
      </div>
    </div>
    <p
      class="text-danger fw-bold text-capitalize"
      v-if="getErrors.cancelAssigning && getErrors.form.length"
    >
      {{ getErrors.cancelAssigning }}
    </p>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <TextInput
          name="email"
          v-model="form.email"
          rules="email|required"
          classes="col-sm-6 col-12"
          label="Email address:"
        />
        <div class="row mt-4">
          <div class="col-md-12">
            <BButton :disabled="getLoadings.tenant" type="submit" block variant="primary">
              <b-spinner
                v-if="getLoadings.tenant"
                label="Spinning"
                small
                variant="white"
              ></b-spinner>
              {{ $t("Add email address tenant") }}
            </BButton>
          </div>
          <div class="col-md-12 pt-0">
            <button
              class="btn btn-white btn-block"
              type="button"
              @click="$bvModal.hide('update-tenant-email')"
            >
              {{ $t("Cancel") }}
            </button>
          </div>
        </div>
      </b-form>
    </ValidationObserver>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Modal from "../ui/Modal.vue";
import { ValidationObserver } from "vee-validate";
import TextInput from "../ui/form/controls/TextInput.vue";

export default {
  props: ["tenant"],
  components: { Modal, ValidationObserver, TextInput },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
  },
  data() {
    return {
      form: {
        email: "",
      },
    };
  },
  mounted() {},
  methods: {
    ...mapActions("tenant", ["updateTenant"]),
    async onSubmit() {
      // this.setTenant(this.tenant);
      await this.updateTenant({ email: this.form.email });
      this.$emit("email-updated");
    },
  },
  watch: {
    tenant() {
      this.form.email = this.tenant?.email;
    },
  },
};
</script>
