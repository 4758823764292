<template>
  <b-modal
    :size="size"
    centered
    :id="modalId"
    :no-close-on-esc="noCloseOnEsc"
    :no-close-on-backdrop="noCloseOnBackdrop"
    :hide-header-close="hideHeaderClose"
    :hide-header="hideHeader"
    :hide-footer="hideFooter"
  >
    <div class="p-3">
      <slot></slot>
    </div>
    <template #modal-footer="{ hide }">
      <slot name="footer" :hide="hide">
        <p></p>
      </slot>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    modalId: {
      type: String,
      required: true,
    },
    noCloseOnEsc: {
      default: true,
      type: Boolean,
    },
    noCloseOnBackdrop: {
      default: true,
      type: Boolean,
    },
    hideHeaderClose: {
      default: true,
      type: Boolean,
    },
    hideHeader: {
      default: false,
      type: Boolean,
    },
    hideFooter: {
      default: false,
      type: Boolean,
    },
    size: {
      default: 'md',
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  padding: 2.5rem;
  @include xs {
    padding: 2rem 1rem;
  }
  border: none;
  border-radius: 0.5rem;
  .btn {
    padding: 0.875rem 0;
    flex-grow: 1;
  }
}
%common-resets {
  padding: 0;
}
.modal-body {
  @extend %common-resets;
}
.modal-header {
  @extend %common-resets;
  border-bottom: none;
}
.modal-footer {
  @extend %common-resets;
  border-top: none;
  justify-content: space-between;
}
.modal-dialog {
  @include customMin(576px) {
    max-width: 566px;
  }
}
</style>
